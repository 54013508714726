import React from "react";
import TeamSectionItem from "../TeamSectionItem";
import {
  IMAGES_DESKTOP,
  CAREERS_IMAGES_COLLAGE_CLASSNAME,
  CAREERS_IMAGES_COLLAGE_COLUMN_LEFT_CLASSNAME,
  CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_CLASSNAME,
  CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_BOTTOM_CLASSNAME,
} from "../../constants";

const Desktop = () => {
  return (
    <div className={CAREERS_IMAGES_COLLAGE_CLASSNAME}>
      <div className={CAREERS_IMAGES_COLLAGE_COLUMN_LEFT_CLASSNAME}>
        <TeamSectionItem images={IMAGES_DESKTOP[0]} />
        <TeamSectionItem images={IMAGES_DESKTOP[1]} />
      </div>
      <div className={CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_CLASSNAME}>
        <TeamSectionItem images={IMAGES_DESKTOP[2]} />
        <div
          className={CAREERS_IMAGES_COLLAGE_COLUMN_RIGHT_BELOW_BOTTOM_CLASSNAME}
        >
          <TeamSectionItem images={IMAGES_DESKTOP[3]} />
          <TeamSectionItem images={IMAGES_DESKTOP[4]} />
        </div>
      </div>
    </div>
  )
}

export default Desktop;
